import { HelpCircle, Loader2, Search } from 'lucide-react';
import {
  Button,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@pulse/ui/components';
import { Form, useNavigation } from '@remix-run/react';
import { ActionFunctionArgs, redirect } from '@remix-run/node';

export async function action({ request, context }: ActionFunctionArgs) {
  const formData = await request.formData();
  const policyNumber = formData.get('policyNumber') as string | null;
  const lastName = formData.get('lastName') as string | null;
  const zip = formData.get('zip') as string | null;

  context.log.info(`Lookup form submitted ${policyNumber} ${lastName} ${zip}`);

  if (!lastName || !zip || !policyNumber) {
    throw new Response(
      `Missing required fields last name and/or zip and/or policy number`,
      {
        status: 400,
        statusText: 'Bad Request',
      },
    );
  }

  // const response = await createLookup({
  //   policyNumber: policyNumber as string,
  //   lastName: lastName as string,
  //   zip: zip as string,
  // });

  // It ensures the lookup is created in the read-db before redirecting
  // This is a sub-optimal solution because we need to be aware of the implementation details
  // await sleep(3500);

  // return redirect(`/lookup/${response.identifier}`);

  const lastNameEncoded = encodeURIComponent((lastName as string).trim());
  const zipEncoded = encodeURIComponent((zip as string).trim());
  const policyNumberEncoded = encodeURIComponent(
    (policyNumber as string).trim(),
  );

  const result = await context.policyRepository.findFuzzyPolicyData({
    lastName: lastNameEncoded,
    zip: zipEncoded,
    policyNumber: policyNumberEncoded,
  });

  if (!result) {
    return redirect(
      `/policy/404?lastName=${lastNameEncoded}&zip=${zipEncoded}&policyNumber=${policyNumberEncoded}`,
    );
  }

  return redirect(
    `/policy?lastName=${lastNameEncoded}&zip=${zipEncoded}&policyNumber=${policyNumberEncoded}`,
  );
}

export default function LookupFormIndex() {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';

  return (
    <Form method='post'>
      <CardHeader className='space-y-2.5'>
        <img
          src='/Lookup_Icon.svg'
          alt='Lookup icon'
          className='w-16 h-16'
          width={64}
          height={64}
        />
        <CardTitle>Verify an insurance policy</CardTitle>
        <CardDescription>
          If you are a mortgage company and you need to verify policy
          information on a personal lines homeowners’ policy. Please enter
          policy details and we’ll look it up for you.
        </CardDescription>
      </CardHeader>
      <CardContent className='space-y-4'>
        <div className='relative'>
          <Input
            placeholder='Policy Number...'
            required
            className='pr-10'
            disabled={isSubmitting}
            name='policyNumber'
            maxLength={9}
            pattern='[A-Za-z0-9]{1,9}'
          />
          <div className='absolute inset-y-0 right-0 flex items-center pr-3 text-muted-foreground'>
            <Popover>
              <PopoverTrigger asChild>
                <HelpCircle className='w-4 h-4 cursor-pointer' />
              </PopoverTrigger>
              <PopoverContent className='text-sm max-w-56 text-center'>
                Enter the 7-digit portion of the policy number (exclude letters
                and any numbers following spaces or dashes).
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <div className='relative'>
          <Input
            placeholder='Insured last name...'
            required
            className='pr-10'
            disabled={isSubmitting}
            name='lastName'
          />

          <div className='absolute inset-y-0 right-0 flex items-center pr-3 text-muted-foreground'>
            <Popover>
              <PopoverTrigger asChild>
                <HelpCircle className='w-4 h-4 cursor-pointer' />
              </PopoverTrigger>
              <PopoverContent className='text-sm max-w-56 text-center'>
                Enter last name, including hyphenations
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <div className='relative'>
          <Input
            placeholder='Billing zip code...'
            required
            className='pr-10'
            disabled={isSubmitting}
            name='zip'
          />
          <div className='absolute inset-y-0 right-0 flex items-center pr-3 text-muted-foreground'>
            <Popover>
              <PopoverTrigger asChild>
                <HelpCircle className='w-4 h-4 cursor-pointer' />
              </PopoverTrigger>
              <PopoverContent className='text-sm max-w-56 text-center'>
                Enter 5-digit billing zip code
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <Button type='submit' className='w-full' disabled={isSubmitting}>
          {isSubmitting ? (
            <Loader2 className='w-4 h-4 mr-2 animate-spin' />
          ) : (
            <Search className='w-4 h-4 mr-2' />
          )}
          Lookup Policy
        </Button>
      </CardFooter>
    </Form>
  );
}
